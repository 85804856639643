require('./bootstrap');
import moment from 'moment';
import $ from 'jquery';

import Alpine from 'alpinejs'


window.$ = window.jQuery = $;
window.moment = moment;
window.Alpine = Alpine;
Alpine.start();